@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Vanda-Regular';
  src:
    url('/assets/fonts/fa/vanda/woff2/VandaFAnum-Regular.woff2') format('woff2'),
    url('/assets/fonts/fa/vanda/woff/VandaFAnum-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vanda-Bold';
  src:
    url('/assets/fonts/fa/vanda/woff2/VandaFAnum-Bold.woff2') format('woff2'),
    url('/assets/fonts/fa/vanda/woff/VandaFAnum-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vanda-Light';
  src:
    url('/assets/fonts/fa/vanda/woff2/VandaFAnum-Light.woff2') format('woff2'),
    url('/assets/fonts/fa/vanda/woff/VandaFAnum-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 24 9.8% 10%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 20 14.3% 4.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  body[dir='rtl'] {
    font-size: 24px;
    font-family: 'Vanda-Light', sans-serif;
  }

  body[dir='rtl'] .text-sm {
    font-size: 1.25rem;
  }

  body[dir='rtl'] .text-lg {
    font-size: 1.5rem;
  }

  body[dir='rtl'] .text-xl {
    font-size: 1.75rem;
  }

  body[dir='rtl'] .text-2xl {
    font-size: 2rem;
  }

  body[dir='rtl'] .text-3xl {
    font-size: 2.25rem;
  }

  body[dir='rtl'] .font-bold {
    font-family: 'Vanda-Bold', sans-serif;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 60 9.1% 97.8%;
    --primary-foreground: 24 9.8% 10%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 24 5.7% 82.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
